/*=========================================================================================
  File Name: moduleCourseClassActions.js
  Description: CourseClass Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
import { reject, resolve } from "core-js/fn/promise";
export default {
  addCountry({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("CountrySettings/Add", item)
        .then(response => {
          commit("ADD_COUNTRY", Object.assign(item, { ID: response.data.ID }));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  addCity({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("CountrySettings/AddCityToCountry", item)
        .then(response => {
          commit("ADD_CITY", Object.assign(item, { ID: response.data.ID }));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  UpdateCity({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("CountrySettings/UpdateCityData", item)
        .then(response => {
          if (response.status == 200) {
            commit("UPDATE_CITY", response.data);
            resolve(response);
          }
          // commit("UPDATE_CITY", Object.assign(item));
          // resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchAllCountries({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "https://restcountries.eu/rest/v2/all?fields=name;alpha2Code;flag",
          {
            headers: null
          }
        )
        .then(response => {
          commit("SET_ALLCOUNTRY", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // https: //restcountries.eu/rest/v2/all?fields=name;alpha2Code;flag
  fetchAllCountriesList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/CountrySettings/GetListOfCountries")
        .then(response => {
          if (response.status == 200) {
            commit("SET_COUNTRY", response.data);
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchCountrysByID({ commit }) {
    var Jsonvalue = {};
    Jsonvalue.CountryCode = "All";
    return new Promise((resolve, reject) => {
      axios
        .post("/CountrySettings/Get", Jsonvalue)
        .then(response => {
          if (response.status == 200) {
            commit("SET_COUNTRY", response.data);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateCountry({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/CountrySettings/Update?Id=" + item.Id, item)
        .then(response => {
          commit("UPDATE_COUNTRY", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetCountryById(context, country) {
    if (country.Id == undefined) {
      country.Id = "";
    }
    if (country.CountryCode == undefined) {
      country.CountryCode = " ";
    }
    return new Promise((resolve, reject) => {
      axios
        .post("/CountrySettings/Get", country)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // GetStateList(context, Country) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post("/CountrySettings/GetStateList", Country)
  //       .then(response => {
  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },

  // GetStateList({ commit }){
  //   return new Promise((resolve, reject)=>{
  //     axios.post("/CountrySettings/GetStateList", Country)
  //     .then((response) => {
  //       if(response.status == 200)
  //       {
  //         commit("", response.data);
  //         resolve(response);
  //       }
  //     })
  //     .catch((error) => {reject(error)})
  //   })
  // },

  async GetStateList({ commit }, model) {
    try {
      debugger;
      const response = await axios.post("/CountrySettings/GetStateList", model);
      commit("SET_STATE_LIST", response.data);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  GetCityList(context, Country) {
    return new Promise((resolve, reject) => {
      axios
        .post("/CountrySettings/GetCityList", Country)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  //GetAllCities
  GetCities(context, Country) {
    return new Promise((resolve, reject) => {
      axios
        .post("/CountrySettings/GetAllCities")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addState({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("CountrySettings/AddStateToCountry", item)
        .then(response => {
           commit("ADD_STATE", Object.assign(item, { ID: response.data.ID }));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  DeleteCity(context, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("/CountrySettings/DeleteCity", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  //DeleteState

  DeleteState(context, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("/CountrySettings/DeleteState", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetCityByObject(context, OBJECTID) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("/CountrySettings/GetCityByID", { OBJECTID: OBJECTID } )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
},

};
