/*=========================================================================================
  File Name: moduleAnnualPanState.js
  Description: AnnualPan Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  namespaced: true,
  Country: [],
  AllCountries: [],
  CountryID: "",
  CountryCode: "",
  StateList:'',
  statlis:[],
};
